<template>
  <div>
    <div class="mb-5">
      <b-modal id="add-update-hub-modal" title="Hub Add/Update" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">

          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="name" class="col-form-label col-form-label-sm">Name <span class="custom-required">*</span></label>
              <input type="text" v-model="hub.name" v-validate="'required'" id="name" name="name" class="form-control form-control-sm" placeholder="Enter name"/>
              <div class="invalid-feedback">Name is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="warehouse_id" class="col-form-label col-form-label-sm">Warehouse <span class="custom-required">*</span></label>
              <select v-model="hub.warehouse_id" v-validate="'required'" id="warehouse_id" name="warehouse_id" class="form-control form-control-sm">
                <option selected disabled :value="''">Select warehouse</option>
                <option v-for="(warehouse, index) in warehouses" :key="index" :value="warehouse.id">{{ warehouse.name }}</option>
              </select>
              <div class="invalid-feedback">Warehouse is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="phone" class="col-form-label col-form-label-sm">Phone Number</label>
              <input type="text" v-model="hub.phone" v-validate="'length:11|numeric'" id="phone" name="phone" class="form-control form-control-sm" placeholder="Enter phone no."/>
              <div class="invalid-feedback">Phone no. must be integer and 11 digit</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="email" class="col-form-label col-form-label-sm">Email Address</label>
              <input type="text" v-model="hub.email" v-validate="'email'" id="email" name="email" class="form-control form-control-sm" placeholder="Enter email address"/>
              <div class="invalid-feedback">Email address is invalid</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="status" class="col-form-label col-form-label-sm">Status <span class="custom-required">*</span></label>
              <select v-model="hub.status" v-validate="'required'" id="status" name="status" class="form-control form-control-sm">
                <option :value="''">Select Status</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
              <div class="invalid-feedback">Status is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="hub_zone_id" class="col-form-label col-form-label-sm">Hub Location <span class="custom-required">*</span></label>
              <select v-model="hub.hub_zone_id" v-validate="'required'" id="hub_zone_id" name="hub_zone_id" class="form-control form-control-sm">
                <option selected disabled :value="''">Select hub zone</option>
                <option v-for="(hub_zone, index) in hub_zones" :key="index" :value="hub_zone.id">{{ hub_zone.hub_zone_desc }}</option>
              </select>
              <div class="invalid-feedback">Hub zone is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="name" class="col-form-label col-form-label-sm">Address <span class="custom-required">*</span></label>
              <textarea v-model="hub.address" v-validate="'required'" name="address" id="address" rows="2" class="form-control form-control-sm" placeholder="Enter address"></textarea>
              <div class="invalid-feedback">Address is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="hub_employees" class="col-form-label col-form-label-sm">Hub Employees <span class="custom-required">*</span></label>
              <input type="text" v-model="hub.hub_employees" v-validate="'required|numeric'" id="hub_employees" name="hub_employees" class="form-control form-control-sm" placeholder="Enter hub employees"/>
              <div class="invalid-feedback">Hub employees is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="departments" class="col-form-label col-form-label-sm">Department Working <span class="custom-required">*</span></label>
              <v-select v-model="hub.departments" multiple :class="errors.first('departments') ? 'borderColor' : ''" name="departments" id="departments" v-validate="'required'" label="department_name" :options="departments"></v-select>
              <div v-if="errors.first('departments')" class="error">Department working is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="rent_agreement_expiration" class="col-form-label col-form-label-sm">Rent Agreement Expiration <span class="custom-required">*</span></label>
              <datepicker :format="customRentAgreementDateFormat" v-model="hub.rent_agreement_expiration" v-validate="{ rules: { required:  true } }" :disabled-dates="disabledDates"  :class="errors.has('rent_agreement_expiration') ? 'error-border-color' : ''"  id="rent_agreement_expiration"  name="rent_agreement_expiration" placeholder="Select rent agreement expiration date" ></datepicker>
              <div class="invalid-feedback"> {{ errors.first('rent_agreement_expiration') }}</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="trade_license_expiration" class="col-form-label col-form-label-sm">Trade License Expiration <span class="custom-required">*</span></label>
              <select v-model="hub.trade_license_expiration" v-validate="'required'" id="trade_license_expiration" name="trade_license_expiration" class="form-control form-control-sm">
                <option :value="''">Select trade license expiration</option>
                <option value="1">Yes</option>
                <option value="2">No</option>
              </select>
              <div class="invalid-feedback">Trade license expiration is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="dife_license_expiration" class="col-form-label col-form-label-sm">DIFE License Expiration <span class="custom-required">*</span></label>
              <select v-model="hub.dife_license_expiration" v-validate="'required'" id="dife_license_expiration" name="dife_license_expiration" class="form-control form-control-sm">
                <option :value="''">Select DIFE license expiration </option>
                <option value="1">Yes</option>
                <option value="2">No</option>
              </select>
              <div class="invalid-feedback">DIFE license expiration is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="fire_license_expiration" class="col-form-label col-form-label-sm">Fire License Expiration <span class="custom-required">*</span></label>
              <select v-model="hub.fire_license_expiration" v-validate="'required'" id="fire_license_expiration" name="fire_license_expiration" class="form-control form-control-sm">
                <option :value="''">Select fire license expiration</option>
                <option value="1">Yes</option>
                <option value="2">No</option>
              </select>
              <div class="invalid-feedback">Fire license expiration is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="cleaning_company_id" class="col-form-label col-form-label-sm">Cleaning Company <span class="custom-required">*</span></label>
              <select v-model="hub.cleaning_company_id" v-validate="'required'" id="cleaning_company_id" name="cleaning_company_id" class="form-control form-control-sm">
                <option selected disabled :value="''">Select cleaning company</option>
                <option v-for="(cleaning_company, index) in cleaning_companies" :key="index" :value="cleaning_company.id">{{ cleaning_company.name }}</option>
              </select>
              <div class="invalid-feedback">Cleaning company is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="no_of_cleaners" class="col-form-label col-form-label-sm">No. of Cleaners <span class="custom-required">*</span></label>
              <input type="text" v-model="hub.no_of_cleaners" v-validate="'required|numeric'" id="no_of_cleaners" name="no_of_cleaners" class="form-control form-control-sm" placeholder="Enter np. of cleaners"/>
              <div class="invalid-feedback">No. of cleaners is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="security_company_id" class="col-form-label col-form-label-sm">Security Company <span class="custom-required">*</span></label>
              <select v-model="hub.security_company_id" v-validate="'required'" id="security_company_id" name="security_company_id" class="form-control form-control-sm">
                <option selected disabled :value="''">Select security company</option>
                <option v-for="(security_company, index) in security_companies" :key="index" :value="security_company.id">{{ security_company.name }}</option>
              </select>
              <div class="invalid-feedback">Security company is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="no_of_security" class="col-form-label col-form-label-sm">No. of Security <span class="custom-required">*</span></label>
              <input type="text" v-model="hub.no_of_security" v-validate="'required|numeric'" id="no_of_security" name="no_of_security" class="form-control form-control-sm" placeholder="Enter np. of security"/>
              <div class="invalid-feedback">No. of security is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="location_sft" class="col-form-label col-form-label-sm">Location SFT <span class="custom-required">*</span></label>
              <input type="text" v-model="hub.location_sft" v-validate="'required|numeric|max:10'" id="location_sft" name="location_sft" class="form-control form-control-sm" placeholder="Enter location sft"/>
              <div class="invalid-feedback">Location SFT is required</div>
            </div>
          </div>
          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" v-if="!this.btnEdit" class="text-center mr-2" :loading="loader"  @click.prevent="save">Add</a-button>
              <a-button type="primary" v-if="this.btnEdit" class="text-center mr-2" :loading="loader"  @click.prevent="update">Update</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('add-update-hub-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import $ from 'jquery'

export default {
  name: 'addUpdateHub',
  components: { Datepicker },
  props: ['hub', 'btnEdit'],
  data() {
    return {
      warehouses: [],
      departments: [],
      hub_zones: [],
      cleaning_companies: [],
      security_companies: [],
      validation_errors: {},
      show: false,
      loader: false,
      disabledDates: {
        to: moment().subtract(1, 'days').toDate(),
      },
    }
  },
  mounted() {
    this.getCode()
  },
  methods: {
    customRentAgreementDateFormat(date) {
      this.hub.rent_agreement_expiration = moment(date).format('YYYY-MM-DD')
      return moment(date).format('YYYY-MM-DD')
    },
    getCode() {
      apiClient.get('api/hub/codes')
        .then(response => {
          const data = response.data
          this.warehouses = data.warehouses
          this.hub_zones = data.hub_zones
          this.departments = data.departments
          this.cleaning_companies = data.cleaning_companies
          this.security_companies = data.security_companies
        })
        .catch(error => {
          console.log(error)
        })
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.post('api/hubs', this.hub).then(response => {
            this.loader = false
            this.$notification.success({
              message: response.data.message,
            })
            $('#hub_list').DataTable().destroy()
            this.$emit('resetForm')
            this.$emit('getHubs')
          }).catch(error => {
            this.loader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.put('api/hubs/' + this.hub.id, this.hub)
            .then(response => {
              if (!response.data.error) {
                this.loader = false
                $('#hub_list').DataTable().destroy()
                this.$bvModal.hide('add-update-hub-modal')
                this.$emit('getHubs')
                this.$notification.success({
                  message: response.data.message,
                })
              } else {
                this.loader = false
                this.$notification.error({
                  message: 'Update Failed',
                })
              }
            })
            .catch(error => {
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>
.error{
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.borderColor{
  border: 1px solid red !important;
  border-radius:4px !important;
}
</style>
