<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <a-button class="btn btn-sm btn-info float-left mb-1 mr-1" :loading="excelDownloadLoader" @click.prevent="excelDownload"><i class="fa fa-file-excel-o mr-1"></i> Excel</a-button>
        <a href="javascript: void(0);" class="btn btn-sm btn-success float-left mb-2 mr-1" v-b-modal.add-update-hub-modal @click="$bvModal.show('add-update-hub-modal'), resetForm()">
          <i class="fa fa-plus"/> Add Hub
        </a>
        <table class="table table-sm table-bordered" id="hub_list">
          <thead>
          <tr>
            <th style="width:8%">Hub ID</th>
            <th style="width:12%">Name</th>
            <th style="width:8%">Zone</th>
            <th style="width:10%">Employees</th>
            <th style="width:10%">Rent Agreement Expiration</th>
            <th style="width:10%">Trade License availability</th>
            <th style="width:10%">DIFE License availability</th>
            <th style="width:10%">Fire License availability</th>
            <th style="width:20%">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(hub, index) in hubs" :key="index">
            <td style="width:5%">{{ hub.id }}</td>
            <td>{{ hub.name }}</td>
            <td style="width:15%">{{ hub.hub_zone ? hub.hub_zone.hub_zone_desc : '' }}</td>
            <td style="width:12%">{{ hub.hub_employees || 0 }}</td>
            <td><span v-if="hub.rent_agreement_expiration" :style="hub.rent_agreement_expiration_days <= 45 ? 'color: #E74C3C; font-weight:bold; padding: 5px;' : 'color: #28B463; font-weight:bold; padding: 5px;'">{{ hub.rent_agreement_expiration }}</span></td>
            <td><span v-if="hub.trade_license_expiration" :style="hub.trade_license_expiration === 1 ? 'background-color: #28B463; color: white; font-weight:bold; padding: 5px;' : 'background-color: #E74C3C; color: white; font-weight:bold; padding: 5px;'">{{ hub.trade_license_expiration === 1 ? 'Yes' : 'No'}}</span></td>
            <td><span v-if="hub.dife_license_expiration" :style="hub.dife_license_expiration === 1 ? 'background-color: #28B463; color: white; font-weight:bold; padding: 5px;' : 'background-color: #E74C3C; color: white; font-weight:bold; padding: 5px;'">{{ hub.dife_license_expiration === 1 ? 'Yes' : 'No'}}</span></td>
            <td><span v-if="hub.fire_license_expiration" :style="hub.fire_license_expiration === 1 ? 'background-color: #28B463; color: white; font-weight:bold; padding: 5px;' : 'background-color: #E74C3C; color: white; font-weight:bold; padding: 5px;'">{{ hub.fire_license_expiration === 1 ? 'Yes' : 'No'}}</span></td>
            <td>
              <a class="btn btn-sm btn-info mr-1" href="javascript: void(0);" @click.prevent="view(hub), $bvModal.show('view-hub-modal')"><i class="fe fe-info mr-1"></i>View</a>
              <a class="btn btn-sm btn-primary mr-1" href="javascript: void(0);" @click.prevent="edit(hub.id), $bvModal.show('add-update-hub-modal')"><i class="fe fe-edit mr-1"></i>Update</a>
<!--              <a class="btn btn-sm btn-danger mr-1" href="javascript: void(0);" @click.prevent="onDelete(hub.id)"><i class="fe fe-trash mr-1"></i>Delete</a>-->
            </td>
          </tr>
          </tbody>
        </table>
        <a-skeleton active :loading="loading"></a-skeleton>
        <h5 class="text-center text-secondary" v-if="hubs.length === 0 && !flag">
          <Empty :image="simpleImage"></Empty>
        </h5>
      </div>
    </div>
    <add-update-hub
      :hub="hub"
      :btnEdit="btnEdit"
      @getHubs="getHubs"
      @resetForm="resetForm"
      ref="addUpdateProduct">
    </add-update-hub>
    <view-hub
      :view_hub="view_hub"
      ref="viewHub"></view-hub>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'
import addUpdateHub from '@/views/hub/modal/addUpdateHub'
import viewHub from '@/views/hub/modal/viewHub'
import $ from 'jquery'

export default {
  name: 'List',
  components: { Empty, addUpdateHub, viewHub },
  data() {
    return {
      hubs: [],
      hub: {},
      view_hub: {},
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      excelDownloadLoader: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getHubs()
  },
  methods: {
    getHubs() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/hubs')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.hubs = response.data.hubs
          setTimeout(function() {
            $('#hub_list').DataTable(
              {
                bRetrieve: true,
                pagingType: 'full_numbers',
                pageLength: 25,
                processing: true,
              },
            )
          }, 3000,
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
    edit(id) {
      this.hub = {}
      this.btnEdit = true
      apiClient.get('api/hubs/' + id + '/edit').then(response => {
        if (!response.data.error) {
          this.hub = response.data.hub
        }
      })
    },
    onDelete(hubId) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/hubs/' + hubId).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              $('#hub_list').DataTable().destroy()
              this.getHubs()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    resetForm() {
      this.btnEdit = false
      this.hub = {}
    },
    customDate(date) {
      return moment(date).format('DD-MM-YYYY')
    },
    view(hub) {
      this.view_hub = {}
      this.view_hub = hub
    },
    excelDownload() {
      this.excelDownloadLoader = true
      apiClient.get('api/setting/hub/excel-download', { responseType: 'blob' }).then(response => {
        this.excelDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'hubs.xlsx')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
  table {
    table-layout:fixed;
  }
  td {
    word-wrap:break-word;
  }
  .item:last-child .comma {
    display: none;
  }
</style>
