<template>
  <div v-if="Object.keys(view_hub).length !== 0" >
    <div class="mb-1">
      <b-modal id="view-hub-modal" title="View Hub Details" size="xl" hide-footer>
        <div class="modal-content bg-white rounded">
          <table class="table table-sm">
            <thead>
            <tr>
              <th>Hub Name</th>
              <td>{{ view_hub.name }}</td>
            </tr>
            <tr>
              <th>Address</th>
              <td>{{ view_hub.address }}</td>
            </tr>
            <tr>
              <th>Zone</th>
              <td>{{ view_hub.hub_zone ? view_hub.hub_zone.hub_zone_desc : '' }}</td>
            </tr>
            <tr>
              <th>Hub Employees</th>
              <td>{{ view_hub.hub_employees || 0 }}</td>
            </tr>
            <tr>
              <th>Departments</th>
              <td>
                <template v-for="(department, index) in view_hub.departments">
                <span class="item" :key="index">
                    {{ department.department_name }}
                    <span class="comma">
                        , <br>
                    </span>
                </span>
                </template>
              </td>
            </tr>
            <tr>
              <th>Rent Agreement Expiration</th>
              <td style="padding-top: 10px;"><span v-if="view_hub.rent_agreement_expiration" :style="view_hub.rent_agreement_expiration_days <= 45 ? 'color: #E74C3C; font-weight:bold; padding: 5px;' : 'background-color: color: #28B463; font-weight:bold; padding: 5px;'">{{ view_hub.rent_agreement_expiration }}</span></td>
            </tr>
            <tr>
              <th>Trade License Expiration</th>
              <td style="padding-top: 10px;"><span v-if="view_hub.trade_license_expiration" :style="view_hub.trade_license_expiration === 1 ? 'background-color: #28B463; color: white; font-weight:bold; padding: 5px;' : 'background-color: #E74C3C; color: white; font-weight:bold; padding: 5px;'">{{ view_hub.trade_license_expiration === 1 ? 'Yes' : 'No'}}</span></td>
            </tr>
            <tr>
              <th>DIFE License Expiration</th>
              <td style="padding-top: 10px;"><span v-if="view_hub.dife_license_expiration" :style="view_hub.dife_license_expiration === 1 ? 'background-color: #28B463; color: white; font-weight:bold; padding: 5px;' : 'background-color: #E74C3C; color: white; font-weight:bold; padding: 5px;'">{{ view_hub.dife_license_expiration === 1 ? 'Yes' : 'No'}}</span></td>
            </tr>
            <tr>
              <th>Fire License Expiration</th>
              <td style="padding-top: 10px;"><span v-if="view_hub.fire_license_expiration" :style="view_hub.fire_license_expiration === 1 ? 'background-color: #28B463; color: white; font-weight:bold; padding: 5px;' : 'background-color: #E74C3C; color: white; font-weight:bold; padding: 5px;'">{{ view_hub.fire_license_expiration === 1 ? 'Yes' : 'No'}}</span></td>
            </tr>
            <tr>
              <th>Cleaning Company</th>
              <td>{{ view_hub.cleaning_company ? view_hub.cleaning_company.name : '' }}</td>
            </tr>
            <tr>
              <th>No. of Cleaners</th>
              <td>{{ view_hub.no_of_cleaners || 0 }}</td>
            </tr>
            <tr>
              <th>Security Company</th>
              <td>{{ view_hub.security_company ? view_hub.security_company.name : '' }}</td>
            </tr>
            <tr>
              <th>No. of Security</th>
              <td>{{ view_hub.no_of_security || 0 }}</td>
            </tr>
            <tr>
              <th>Location SFT</th>
              <td>{{ view_hub.location_sft || 0 }}</td>
            </tr>
            </thead>
          </table>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>

export default {
  name: 'viewHub',
  props: ['view_hub'],
  data() {
    return {}
  },
  methods: {},
}
</script>
